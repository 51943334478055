import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthState } from "../../store/authSlice/hook";
import { getContact } from "../../utils/fetch";
import CopyToClipboard from "react-copy-to-clipboard";
import copyAllIcon from '../../assets/pngs/copy-all.png'

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 25px 0 25px;
  position: relative;
`;
const MainCard = styled.div`
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0dfdf;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;
const HeadingDiv = styled.div`
  height: 40px;
  padding: 0 25px 0 25px;
  border-bottom: 1px solid #e0dfdf;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`;
const Option = styled.p`
  &.serial {
    width: 5%;
  }

  &.email {
    width: 25%;
  }
  &.date {
    width: 15%;
  }
  &.name {
    width: 20%;
  }
  &.message {
    width: 35%;
    padding-right: 20px;
  }
`;
const ContentBox = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
`;
const Box = styled.div`
  height: 80px;
  padding: 0 25px 0 25px;
  border-bottom: 1px solid #e0dfdf;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;
const CopyAllIcon = styled.img`
  position: absolute;
  right: 50px;
  top: -60px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:active{
    transform: scale(0.8);
  }
`
const CopyText = styled.h5`
  position: absolute;
  right: 90px;
  top: -52px;
`
const ContactDetailCard = () => {
  const [contacts, setContacts] = useState<any[]>([]);
  const { token } = useAuthState();
  useEffect(() => {
    const fetchContacts = async () => {
      if (token) {
        try {
          const contactData = await getContact(token);
          setContacts(contactData.data);
         
        } catch (error) {
          console.error("Failed to fetch contacts", error);
        }
      }
    };
    fetchContacts();
  }, [token]);
  const sortedcontacts= [...contacts].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
 
  const getAllEmails = () => {
    return sortedcontacts.map(sub => sub.email).join(", ");
  };

  return (
    <MainDiv>
       <CopyToClipboard text={getAllEmails()}>
        <CopyAllIcon src={copyAllIcon} alt="Copy All" />
      </CopyToClipboard>
      <CopyText>Copy All</CopyText>
     
      <MainCard>
        <HeadingDiv>
          <Option className="serial">S.No</Option>
          <Option className="name">Full Name</Option>
          <Option className="email">Email</Option>
          <Option className="date">Date</Option>
          <Option className="message">Message</Option>
        </HeadingDiv>
        <ContentBox>
          {sortedcontacts.map((contact,index)=>(
            <Box key={index}>
            <Option className="serial">{index+1}</Option>
            <Option className="name">{contact.name}</Option>
            <Option className="email">{contact.email}</Option>
            <Option className="date">{new Date(contact.updatedAt).toLocaleDateString('en-GB')}</Option>
            <Option className="message">{contact.message}</Option>
          </Box>
          ))}
          
          
        </ContentBox>
      </MainCard>
    </MainDiv>
  );
};

export default ContactDetailCard;
