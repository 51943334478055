import axios from "axios";





export const getFeedbackSubscriber = async (token: string) => {
    try {
      const response = await axios.get(
        "https://admin-api.buckmint.org/api/feedback/subscribers",
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to feedback Subscriber contacts", error);
      throw error;
    }
  };


export const getContact = async (token: string) => {
  try {
    const response = await axios.get(
      "https://admin-api.buckmint.org/api/contact/get-contact",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch contacts", error);
    throw error;
  }
};

export const getDetailedFeedbacks = async (token: string) => {
  try {
    const response = await axios.get(
      "https://admin-api.buckmint.org/api/feedback/detailed-feedbacks",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch detailed feedbacks", error);
    throw error;
  }
};

export const getQuickFeedbacks = async (token: string) => {
  try {
    const response = await axios.get(
      "https://admin-api.buckmint.org/api/feedback/quick-feedbacks",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch quick feedbacks", error);
    throw error;
  }
};

