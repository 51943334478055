import React, {  useState } from "react";
import styled from "styled-components";
import lockIcon from "../../assets/login-page-icons/lock-icon.svg";
import userIcon from "../../assets/login-page-icons/user-icon.svg";
import axios from "axios";
import { useAuthState, useSetAuthTokens } from "../../store/authSlice/hook";
import toast, { Toaster } from "react-hot-toast";
const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
`;
const LoginCard = styled.div`
  width: 450px;
  height: 500px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 100px 25px 25px 25px;
  align-items: center;
  border-radius: 16px;
  gap: 20px;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
`;
const InputBox = styled.div`
  height: 50px;
  width: 80%;
  position: relative;
`;
const Input = styled.input`
  padding-left: 50px;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  border: none;
  outline: none;
  background-color: #f0edff;
  font-size: 16px;
  font-family: "open sans";
`;
const Image = styled.img`
  position: absolute;
  left: 25px;
  top: 18px;
  height: 18px;
  width: 18px;
`;
const Button = styled.button`
  outline: none;
  border: none;
  height: 50px;
  width: 100px;
  border-radius: 16px;
  background-image: linear-gradient(#9181f4, #5038ed);
  color: white;
  cursor: pointer;
  margin-top: 20px;
`;

interface props {
    setIsLogin: React.Dispatch<React.SetStateAction<boolean>>
}
const Index = ({setIsLogin}:props) => {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const setAuthTokens = useSetAuthTokens();
  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://admin-api.buckmint.org/api/auth/login",
        {
          username: username, 
          password: password,
        }
      );
      localStorage.setItem('userId',response.data.payload.uid)
      const { token, refreshToken } = response.data;
      setAuthTokens(token, refreshToken);
    } catch (error) {
        toast.error("invalid credential.");
      console.error("Login failed", error);
    }
  };
 

  return (
    <MainDiv>
        <Toaster
        position="top-right"
        />
      <LoginCard>
        <h2>Login</h2>
        <Text>Login to view Admin Panel</Text>
        <InputBox>
          <Image src={userIcon} alt="" />
          <Input
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </InputBox>
        <InputBox>
          <Image src={lockIcon} alt="" />
          <Input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </InputBox>
        <Button onClick={handleLogin}>Login</Button>
      </LoginCard>
    </MainDiv>
  );
};

export default Index;
