import React, { useState } from "react";
import styled from "styled-components";
import downArrowIcon from "../../assets/pngs/down-arrow.png";
import moment from "moment";
const MainDiv = styled.div`
  width: 100%;
  padding: 0 25px 0 25px;
`;

const Box = styled.div<{ isBoxOpen: boolean }>`
  height: ${(props) => (props.isBoxOpen ? "auto" : "190px")};
  overflow: ${(props) => (props.isBoxOpen ? "auto" : "hidden")};
  padding: 25px 25px 25px 25px;
  width: 100%;
  gap: 25px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  transition: 0.4s ease-in-out;
  /*  */
  background-color: #ffffff;
  border: 1px solid #e0dfdf;
  border-bottom: 4px solid #03c988;
  /* border-top: 4px solid #03C988; */
  border-radius: 8px;
  position: relative;
`;
const ReviewBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
`;
const CategoryBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const Rating = styled.p`
  background-color: #cdd2d4;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
`;
const DownArrowImg = styled.img`
  width: 24px;
`
const DownArrow = styled.div<{ isBoxOpen: boolean }>`
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
  transform: rotate(${(props) => (props.isBoxOpen ? "180deg" : "0deg")});
  transition: 0.4s;
`;
const Date = styled.div`
  position: absolute;
  top: 20px;
  right: 70px;
`;
interface props{
  data:any
  index:number
}
const DetailCard = ({data, index}:props) => {

  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
  const formattedDate = moment(data.updatedAt).format('MMMM Do YYYY, h:mm a');
  return (
    <MainDiv>
      <Box isBoxOpen={isBoxOpen}>
        <Date>
        <h5>{formattedDate}</h5>
        </Date>
        <DownArrow isBoxOpen={isBoxOpen}>
        <DownArrowImg
          src={downArrowIcon}
          onClick={() => setIsBoxOpen(!isBoxOpen)}
          alt=""
        />
        </DownArrow>
        <CategoryBox>
          <ReviewBox>
            <h4>Help us improve</h4>
          </ReviewBox>
          <CommentBox>
            <h5>Subject -</h5>
            {data.helpUsImproveTitle}
          </CommentBox>
          <CommentBox>
            <h5>Comment -</h5>
           {data.helpUsImproveText}
          </CommentBox>
        </CategoryBox>
        <CategoryBox>
          <ReviewBox>
            <h4>Deposit Experience</h4>
            <p>-</p>
            <Rating>{data.depositFeedack}</Rating>
          </ReviewBox>
          <CommentBox>
            <h5>Comment -</h5>
            {data.depositFeedbackText}
          </CommentBox>
        </CategoryBox>
        <CategoryBox>
          <ReviewBox>
            <h4>Trade Experience</h4>
            <p>-</p>
            <Rating>{data.tradeFeedack}</Rating>
          </ReviewBox>
          <CommentBox>
            <h5>Comment -</h5>
           {data.tradeFeedbackText}
          </CommentBox>
        </CategoryBox>
        <CategoryBox>
          <ReviewBox>
            <h4>Withdraw Experience</h4>
            <p>-</p>
            <Rating>{data.withdrawFeedack}</Rating>
          </ReviewBox>
          <CommentBox>
            <h5>Comment -</h5>
           {data.withdrawFeedbackText}
          </CommentBox>
        </CategoryBox>
        <CategoryBox>
          <ReviewBox>
            <h4>Overall Experience</h4>
          </ReviewBox>
          <CommentBox>
            <h5>Subject -</h5>
            {data.overAllExperienceTitle}
          </CommentBox>
          <CommentBox>
            <h5>Comment -</h5>
           {data.overAllExperienceText}
          </CommentBox>
        </CategoryBox>

        <CategoryBox>
          <ReviewBox>
            <h4>Email</h4>
          </ReviewBox>
          <CommentBox>{data.email}</CommentBox>
          <CommentBox>
            <p>{data.subscribe ? 'YES':'NO'}</p>
          </CommentBox>
        </CategoryBox>
      </Box>
    </MainDiv>
  );
};

export default DetailCard;
