import React, { useState } from 'react'
import styled from 'styled-components'
import avatarIcon from '../../assets/pngs/avatar.png'
import logoutIcon from '../../assets/pngs/logout-icon.png'
import { useClearAuthTokens } from '../../store/authSlice/hook'
const MainDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px 0px 25px;
`
const Icon = styled.img`
    width: 36px;
    height: 36px;
    cursor: pointer;
`
const InputBox = styled.div`
    height: 36px;
    width: 80px;
`
const Box2 = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
`
const Mail = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  
`
const MenuBar = styled.div`
  width: 100px;
  height: 35px;
  border-radius: 16px;
  background-color: #ffffff;
  position: absolute;
  top: 50px;
  right: 30px;
  border: 1px solid rgba(0,0,0,0.6) ;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`
const LogoutIcon = styled.img`
  width: 16px;
  height: 16px;
`
const UserIdText = styled.p`
  font-weight: 500;
  color: rgba(0,0,0,0.68);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`
const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false)
  const clearAuthToken = useClearAuthTokens()
  const userId = localStorage.getItem('userId');
  const handleLogOut =()=>{
    clearAuthToken()
    window.location.reload(); 
  }
  return (
    <MainDiv>
        <InputBox>
        
        </InputBox>
        <Box2>
          <UserIdText>{userId}</UserIdText>
        <Icon onClick={()=>setIsMenuActive(!isMenuActive)} src={avatarIcon} alt="" />
        </Box2>
        {isMenuActive &&
        <MenuBar onClick={()=>{
          setIsMenuActive(!isMenuActive)
          handleLogOut()
          }}>
          <h5 style={{color:'#343232'}}>Log Out</h5>
          <LogoutIcon src={logoutIcon} alt="" />
        </MenuBar>
        }
    </MainDiv>
  )
}

export default Header