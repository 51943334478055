import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ContactTitleBox from '../../components/ContactDetailsComponents/ContactTitleBox'
import ContactDetailCard from '../../components/ContactDetailsComponents/ContactCard'


const MainDiv = styled.div`
    width: 100%;
    height: 100%;
`
const TitleDiv = styled.div`
    width: 100%;
    height: 100px;
`
const DetailCardDiv = styled.div`
    height: calc(100% - 100px);
    width: 100%;
`
const Index = () => {

  return (
    <MainDiv>
      
        <TitleDiv>
          <ContactTitleBox/> 
        </TitleDiv>
        <DetailCardDiv>
            <ContactDetailCard/>
        </DetailCardDiv>
    </MainDiv>
  )
}

export default Index