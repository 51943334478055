import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SideBar from "../components/Layout/SideBar";
import RightDivs from "../components/Layout/RightDivs/RightDivs";
import LoginPage from "../pages/LoginPage";
import { useAuthState, useSetAuthTokens } from "../store/authSlice/hook";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  display: flex;
`;
const SideBarDiv = styled.div`
  width: 260px;
  height: 100%;
  border-right: 1px solid #e0dfdf;
  /* box-shadow: 25px 0px 20px -20px rgba(0, 0, 0, 0.05),
    6px -6px 20px -20px rgba(0, 0, 0, 0.05); */
`;
const RightDiv = styled.div`
  width: calc(100% - 260px);
  height: 100%;
`;
const Home = () => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const setAuthTokens = useSetAuthTokens();
  const { token, refreshToken } = useAuthState();

    useEffect(() => {
    const handleRefreshToken = async () => {
      try {
        const response = await axios.post(
          "https://admin-api.buckmint.org/api/auth/refresh",
          {
            refreshToken: refreshToken,
          }
        );
        const { token: newToken, refreshToken: newRefreshToken } = response.data;
        setAuthTokens(newToken, newRefreshToken);
      } catch (error) {
        console.error("Refresh Token failed", error);
      }
    };

    const isAboutToExpire = () => {
      if (token) {
        const decodedToken = jwtDecode(token);

        if (decodedToken.exp) {
          const remainingTime = decodedToken.exp * 1000 - Date.now();
          return remainingTime < 7200000 && decodedToken.exp * 1000 > Date.now();
        }
      }
    };

    const isTokenExpired = () => {
      if (!token) {
        return true;
      }
      const decodedToken = jwtDecode(token);
      if (!decodedToken.exp) {
        return true;
      }
      return decodedToken.exp * 1000 < Date.now(); 
    };

    if (isTokenExpired()) {
      setIsLogin(false)
      localStorage.clear();
    } else if (isAboutToExpire()) {
      handleRefreshToken()
    }else if(!isTokenExpired()){
      setIsLogin(true);
    }
  }, [token, refreshToken , setAuthTokens]);

  return (
    <MainDiv>
      <SideBarDiv>
        <SideBar />
      </SideBarDiv>
      <RightDiv>
        {!isLogin ? <LoginPage setIsLogin={setIsLogin} /> : <RightDivs />}
      </RightDiv>
    </MainDiv>
  );
};

export default Home;
