import React from "react";
import moment from "moment";
import styled from "styled-components";

const MainDiv = styled.div`
  width: 350px;
  height: 370px;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-bottom: 4px solid #03c988;
`;
const Heading = styled.p`
  font-family: "open sans";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
`;
const Rating = styled.p`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 40px;
`;

const Comment = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  width: 100%;
  height: 150px;
  overflow-y: auto;
`;
const Subject = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  width: 100%;

  overflow-y: auto;
`;
const Date = styled.div`
  position: relative;
  margin-left: auto;
  font-size: 12px;
`;

interface props {
  selected: string;
  data: any;
  index: number;
}
const SingleDetailCard = ({ selected, data, index }: props) => {
  const formattedDate = moment(data.updatedAt).format('MMMM Do YYYY, h:mm a');
  return (
    <MainDiv>
      {selected !== "HelpUs" ? (
        <Heading>{selected} Experience</Heading>
      ) : (
        <Heading>Improvement Feedback</Heading>
      )}
      {selected !== "Overall" && selected !== "HelpUs" ? (
        <Rating>
          {selected === "Deposit"
            ? data.depositFeedack
            : selected === "Trade"
            ? data.tradeFeedack
            : selected === "Withdraw"
            ? data.withdrawFeedack
            : ""}
        </Rating>
      ) : (
        <>
          <h5>Subject -</h5>
          <Subject>
            {selected==="Overall"?data.overAllExperienceTitle:data.helpUsImproveTitle}
          </Subject>
        </>
      )}

      <h5>Comment -</h5>
      <Comment>
      {selected === "Deposit"
            ? data.depositFeedbackText
            : selected === "Trade"
            ? data.tradeFeedbackText
            : selected === "Withdraw"
            ? data.withdrawFeedbackText
            : selected==='Overall'
            ? data.overAllExperienceText
            : data.helpUsImproveText}
      </Comment>
      <Date>{formattedDate}</Date>
    </MainDiv>
  );
};

export default SingleDetailCard;
