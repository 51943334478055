import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logoName from "../../assets/logo/logo-name.svg";
import logoIcon from "../../assets/logo/logo-icon.svg";
import subscribersIcons from "../../assets/pngs/subscribers.png";
import quickFeedback from "../../assets/pngs/quick-feedback.png";
import detailedFeedback from "../../assets/pngs/detailed-feedback.png";
import contactIcon from "../../assets/pngs/contact-icon.png";
import feedbackSubscriberIcon from '../../assets/pngs/feedback-subscriber.png'
import { Link, useLocation } from "react-router-dom";
import blogIcon from '../../assets/pngs/blog-icon.png'

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 10px;
`;
const LogoDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 80px;
`;
const Heading = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
`;
const OptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Option = styled.div<{selected:string}>`
  display: flex;
  gap: 6px;
  font-family: Montserrat;
  font-size: 14px;
  align-items: center;
  padding: 5px 0 5px 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.751);
  cursor: pointer;
  &.subscriber {
    /* background-color: ${(props)=>props.selected==="subscribers"?'#03c9872b':''}; */
    border-top:${(props)=>props.selected==="subscribers"?' 2px solid #03C988':''};
    border-bottom:${(props)=>props.selected==="subscribers"?' 2px solid #03C988':''};
  }
  &.quick-feedback {
        /* background-color: ${(props)=>props.selected==="subscribers"?'#03c9872b':''}; */
        border-top:${(props)=>props.selected==="quickfeedback"?' 2px solid #03C988':''};
    border-bottom:${(props)=>props.selected==="quickfeedback"?' 2px solid #03C988':''};
  }
  &.detailed-feedback {
    border-top:${(props)=>props.selected==="detailfeedback"?' 2px solid #03C988':''};
    border-bottom:${(props)=>props.selected==="detailfeedback"?' 2px solid #03C988':''};
  }
  &.contact {
    border-top:${(props)=>props.selected==="contactdetail"?' 2px solid #03C988':''};
    border-bottom:${(props)=>props.selected==="contactdetail"?' 2px solid #03C988':''};
  }
  &.feedbacksubscriber{
    border-top:${(props)=>props.selected==="feedbacksubscriber"?' 2px solid #03C988':''};
    border-bottom:${(props)=>props.selected==="feedbacksubscriber"?' 2px solid #03C988':''};
  }
  &.blog{
    border-top:${(props)=>props.selected==="blog"?' 2px solid #03C988':''};
    border-bottom:${(props)=>props.selected==="blog"?' 2px solid #03C988':''};
  }
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const SideBar = () => {
    const [selected, setSelected] = useState('')
    const location = useLocation()

    React.useEffect(() => {
        if (location.pathname === '/contactdetail') {
            setSelected('contactdetail');
        } else if (location.pathname === '/detailfeedback') {
            setSelected('detailfeedback');
        } else if (location.pathname === '/quickfeedback') {
            setSelected('quickfeedback');
        }else if (location.pathname ==='/feedbacksubscriber'){
            setSelected('feedbacksubscriber')
        } 
        else if (location.pathname ==='/blog'){
          setSelected('blog')
      }        
        else {
            setSelected('subscribers');
        }
    }, [location.pathname]);
    
  return (
    <MainDiv>
      <LogoDiv>
        <img src={logoIcon} alt="" />
        <img src={logoName} alt="" />
      </LogoDiv>
      <Heading>Admin</Heading>
      <OptionDiv>
      <Link to={'/'}>
        <Option selected={selected} className="subscriber">
          <Icon src={subscribersIcons} alt="" />
          Subscribers
        </Option>
        </Link>
        <Link to={'/quickfeedback'}>
        <Option  selected={selected} className="quick-feedback">
          <Icon src={quickFeedback} alt="" />
          Quick Feedbacks
        </Option>
        </Link>
        <Link to={'/detailfeedback'}>
        <Option selected={selected} className="detailed-feedback">
          <Icon src={detailedFeedback} alt="" />
          Detailed Feedbacks
        </Option>
        </Link>
        <Link to={'/contactdetail'}>
        <Option selected={selected} className="contact">
          <Icon src={contactIcon} alt="" />
          Contact Details
        </Option>
        </Link>
        <Link to={'/feedbacksubscriber'}>
        <Option selected={selected} className="feedbacksubscriber">
          <Icon src={feedbackSubscriberIcon} alt="" />
          Feedback Subscriber
        </Option>
        </Link>
        <Link to={'/blog'}>
        <Option selected={selected} className="blog">
          <Icon src={blogIcon} alt="" />
          Upload Blog
        </Option>
        </Link>
      </OptionDiv>
    </MainDiv>
  );
};

export default SideBar;
