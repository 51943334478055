import React from 'react'
import styled from 'styled-components'
import Header from '../Header'
import { Route, Routes } from 'react-router-dom'
import SubscriberPage from '../../../pages/SubscriberPage'
import QuickfeedbackPage from '../../../pages/QuickFeedbackPage'
import DetailedFeedbackPage from '../../../pages/DetailedFeedbackPage'
import ContactDetailsPage from '../../../pages/ContactDetailsPage'
import FeedbackSubsPage from '../../../pages/FeedbackSubsPage'
import BlogUpload from '../../../pages/BlogPage/BlogUpload'
const MainDiv = styled.div`
    height: 100%;
    width: 100%;
`
const HeaderDiv = styled.div`
    height: 60px;
    width: 100%;
    box-shadow: rgba(33, 35, 38, 0.16) 0px 10px 10px -10px;
    border-bottom: 1px solid #e0dfdf;
`
const ContentDiv = styled.div`
    background-color: #f8f9fa;
    width: 100%;
    height: calc(100% - 60px);
`
const RightDivs = () => {
  return (
    <MainDiv>
        <HeaderDiv>
            <Header/>
        </HeaderDiv>
        <ContentDiv>
            <Routes>
                <Route path='/' element={<SubscriberPage/>} />
                <Route path='/quickfeedback' element={<QuickfeedbackPage/>} />
                <Route path='/detailfeedback' element={<DetailedFeedbackPage/>} />
                <Route path='/contactdetail' element={<ContactDetailsPage/>} />
                <Route path='/feedbacksubscriber' element={<FeedbackSubsPage/>} />
                <Route path='blog' element={<BlogUpload/>}/>
            </Routes>
        </ContentDiv>
    </MainDiv>
  )
}

export default RightDivs