import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState, AppDispatch } from '../store';
import { setAuthTokens, clearAuthTokens } from './authSlice';

export const useSetAuthTokens = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (token: string, refreshToken: string) => {
      dispatch(
        setAuthTokens({
          token,
          refreshToken,
        })
      );
    },
    [dispatch]
  );
};

export const useClearAuthTokens = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(() => {
    dispatch(clearAuthTokens());
  }, [dispatch]);
};

export const useAuthState = () => {
  return useSelector((state: RootState) => state.auth);
};
