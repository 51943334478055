import React from 'react'
import styled from 'styled-components'
import SubscriberTitleBox from '../../components/SubscriberComponents/SubscriberTitleBox'
import SubscriberDetailCard from '../../components/SubscriberComponents/SubscriberDetailCard'
import FeedbackSubscriberTitleBox from '../../components/FeedbackSubscriber/feedbackSubscriberTitleBox'
import FeedbackSubscriberDetailCard from '../../components/FeedbackSubscriber/FeedbackSubscriberDetailCard'

const MainDiv = styled.div`
    width: 100%;
    height: 100%;
`
const TitleDiv = styled.div`
    width: 100%;
    height: 100px;
`
const DetailCardDiv = styled.div`
    height: calc(100% - 100px);
    width: 100%;
`
const index = () => {
  return (
    <MainDiv>
        <TitleDiv>
          <FeedbackSubscriberTitleBox/> 
        </TitleDiv>
        <DetailCardDiv>
            <FeedbackSubscriberDetailCard/>
        </DetailCardDiv>
    </MainDiv>
  )
}

export default index