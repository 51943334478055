import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DetailCard from "./DetailCard";
import SingleDetailCard from "../SingleDetailCard";
import { useAuthState } from "../../store/authSlice/hook";
import { getDetailedFeedbacks } from "../../utils/fetch";
const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 25px 0 25px;
`;
const MainCard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SingleCardBox = styled.div`
    width: 100%;
  height: 100% ;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  row-gap: 10px;
`
interface props {
  selected: string;
}
const DetailFeedbackDetailCard = ({ selected }: props) => {
  const [detailedFeedback, setDetailedFeedback] = useState<any[]>([])
  const {token} = useAuthState()
  
  useEffect(()=>{
    const fetchSubscriber= async ()=>{
      if (token) {
        try {
          const Data =await getDetailedFeedbacks(token)
          setDetailedFeedback(Data.data)
        } catch (error) {
          console.error("Failed to fetch contacts", error);
        }
      }
    };
    fetchSubscriber()
  },[token])
  const sortedDetailedFeedback= [...detailedFeedback].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  
  return (
    <MainDiv>
      <MainCard>
          {selected === "All" ? (
            <ContentBox>
              {sortedDetailedFeedback.map((data, index)=>(
                <DetailCard key={index} index={index} data={data}/>
              ))}
            </ContentBox>
          ):
          (
            <SingleCardBox>
              {sortedDetailedFeedback.map((data, index)=>(
                <SingleDetailCard key={index} index={index} data={data} selected={selected}/>
              ))}
            </SingleCardBox>)
        }
      </MainCard>
    </MainDiv>
  );
};

export default DetailFeedbackDetailCard;
