import React, { useState } from "react";
import styled from "styled-components";

const MainDiv = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  /* gap: 10px; */
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 10px;
`;
const CategoryItem = styled.p<{selected:boolean}>`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding:10px;
  border: 1px solid #171717ab;
  border-radius: 8px;

  &:hover {
    background-color: #bddbae37;
  }
  &.crypto {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.carboncredit {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.decentralisation {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.finance {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.blockchain {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.web {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.decentralised-exchanges {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.environment {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.climate-change {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.sustainability {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.carbon-neutrality {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
  &.environment {
    background-color: ${(props) => (props.selected ? "#03C988" : "")};
  }
`;

interface props{
    selectedList:string[];
    setSelectedList: React.Dispatch<React.SetStateAction<string[]>>;
}
const Category = ({selectedList,setSelectedList}:props) => {
  
  const handleClick = (category: string) => {
    setSelectedList((previousList) =>
      previousList.includes(category)
        ? previousList.filter((item) => item !== category)
        : [...previousList, category]
    );
  };
  return (
    <MainDiv>
      <CategoryItem
        className="crypto"
        onClick={() => handleClick("Cryptocurrency")}
        selected={selectedList.includes("Cryptocurrency")}
      >
        CRYPTOCURRENCY
      </CategoryItem>
      <CategoryItem
        className="carboncredit"
        onClick={() => handleClick("Carbon Credit")}
        selected={selectedList.includes("Carbon Credit")}
      >
        CARBON CREDIT
      </CategoryItem>
      <CategoryItem
        className="decentralisation"
        onClick={() => handleClick("Decentralization")}
        selected={selectedList.includes("Decentralization")}
      >
        DECENTRALIZATION
      </CategoryItem>
      <CategoryItem
        className="finance"
        onClick={() => handleClick("Finance")}
        selected={selectedList.includes("Finance")}
      >
        {" "}
        FINANCE
      </CategoryItem>
      <CategoryItem
        className="blockchain"
        onClick={() => handleClick("Blockchain")}
        selected={selectedList.includes("Blockchain")}
      >
        BLOCKCHAIN
      </CategoryItem>
      <CategoryItem
        className="web"
        onClick={() => handleClick("Web 3.0")}
        selected={selectedList.includes("Web 3.0")}
      >
        {" "}
        WEB 3.0
      </CategoryItem>
      <CategoryItem
        className="decentralised-exchanges"
        onClick={() => handleClick("Decentralized Exchanges")}
        selected={selectedList.includes("Decentralized Exchanges")}
      >
        DECENTRALIZED EXCHANGES
      </CategoryItem>
      <CategoryItem
        className="environment"
        onClick={() => handleClick("Environment")}
        selected={selectedList.includes("Environment")}
      >
        {" "}
        ENVIRONMENT
      </CategoryItem>
      <CategoryItem
        className="climate-change"
        onClick={() => handleClick("Climate Assets")}
        selected={selectedList.includes("Climate Assets")}
      >
        CLIMATE ASSETS
      </CategoryItem>
      <CategoryItem
        className="sustainability"
        onClick={() => handleClick("Sustainability")}
        selected={selectedList.includes("Sustainability")}
      >
        SUSTAINABILITY
      </CategoryItem>
      <CategoryItem
        className="carbon-neutrality"
        onClick={() => handleClick("Carbon Neutrality")}
        selected={selectedList.includes("Carbon Neutrality")}
      >
        {" "}
        CARBON NEUTRALITY
      </CategoryItem>
    </MainDiv>
  );
};

export default Category;
