import React, { useState } from 'react'
import styled from 'styled-components'
import DetailTitleBox from '../../components/DetailedFeedbackComponents/DetailedFeedbackTitleBox'
import DetailFeedbackDetailCard from '../../components/DetailedFeedbackComponents/DetailedFeedbackCard'

const MainDiv = styled.div`
    width: 100%;
    height: 100%; 
`
const TitleDiv = styled.div`
    width: 100%;
    height: 100px;
`
const DetailCardDiv = styled.div`
    height: calc(100% - 100px);
    width: 100%;
`
const Index = () => {
  const [selected, setSelected] = useState('All')
  return (
    <MainDiv>
        <TitleDiv>
          <DetailTitleBox setSelected={setSelected} selected={selected}/> 
        </TitleDiv>
        <DetailCardDiv>
            <DetailFeedbackDetailCard selected={selected}/>
        </DetailCardDiv>
    </MainDiv>
  )
}

export default Index;