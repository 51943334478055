import axios from 'axios';
import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useAuthState } from '../../store/authSlice/hook';
import toast, { LoaderIcon, Toaster } from 'react-hot-toast';
import UploadImage from './UploadImage';
import Category from './Category';

const MainDiv = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 40px;
`
const TitleInputBox = styled.div`
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const TitleInput = styled.input`
    height: 100%;
    width: 80%;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.132);
    outline: none;
    padding: 5px;
    border-radius: 8px;
`
const SubHeadingBox = styled.div`
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const ContentBox = styled.div`
    width: 70%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const ContentBoxInput = styled.textarea`
    height: 100%;
    width: 80%;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.132);
    outline: none;
    padding: 5px;
    border-radius: 8px;
`
const ImageUrlBox = styled.div`
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 120px;
`
const CategoryBox = styled.div`
    width: 95%;
    height: 80px;
    display: flex;
    align-items: center;
    gap: 50px;
`
const CategoryInput = styled.input`
    height: 100%;
    width: 140px;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.132);
    outline: none;
    padding: 5px;
    border-radius: 8px;
`
const PlusIcon = styled.div`
    height: 100%;
    width: 40px;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.132);
    outline: none;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #212121;
    cursor: pointer;
`
const UploadButtonDiv = styled.div`
    width: 70%;
    height: 50px;
    display: flex;
    justify-content: center;

`
const UploadButton= styled.button`
    border: none ;
    outline: none;
    border-radius: 8px;
    box-shadow: 2px 4px 10px 4px #413f3f57;
    height: 50px;
    width: 150px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #ffffff;
    &:hover{
        background-color: #ede5e5;
    }
    &:active{
        transform: scale(0.9);
    }
`

const BlogUpload = () => {
    const {token} = useAuthState()
    const [title, setTitle] = useState('');
    const [subHeading, setSubHeading] = useState('');
    const [description, setDescription] = useState('');
    const [categoryList, setCategoryList] = useState<string[]>([]);
    const [structuredData, setStructuredData] = useState('')
    const [permalink , setPermalink] = useState('')
    const [image, setImage] = useState<File | null>(null);
    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {setTitle(e.target.value);}
    const handleSubHeadingChange = (e: ChangeEvent<HTMLInputElement>) => setSubHeading(e.target.value);
    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value);
    const [imageUrl, setImageUrl] = useState('')
    const [loading, setLoading] = useState<boolean>(false)
    const handleUploadClick = async()=>{
        setLoading(true)
         const formData = new FormData();
        formData.append("title" , title)
        formData.append("metaDescription", subHeading);
        formData.append("content", description);
        formData.append("imageAltText", "");
        formData.append("structuredData", structuredData);
        formData.append("permalink", permalink);
        formData.append("imageUrl",imageUrl)
        formData.append('canonicalUrl','')
        formData.append( "robotsTxt","")
        formData.append("sitemap", "")
        categoryList.forEach(category => {
            formData.append("category", category);
        });
        if (image){
            formData.append("image", image);
        }

        try {
            await axios({ 
          method: "post",
          url: "https://admin-api.buckmint.org/api/blog/create-blog",
          
            headers: {
                "Content-Type": "multipart/form-data",
              "x-access-token": token,
            },
          data:formData,
        //    {
        //     title:title,
        //     metaDescription:subHeading,
        //     content:description,
        //     imageAltText:"",
        //     structuredData:structuredData,
        //     permalink:permalink,
        //     category:categoryList,
        //     imageUrl:imageUrl,
        //     image:image,
        //     canonicalUrl:"",
        //     robotsTxt:"",
        //     sitemap:"",
        //     // formData
        //   },
        }).then(() => {
            setLoading(false)
            console.log("successful")
            toast.success('Successfully Uploaded!')
        })
      } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log("Blog post failed", error.response?.data || error.message);
            toast.error(error.response?.data?.message || error.message);
        } else {
            console.log("Blog post failed", error);
            toast.error('An unknown error occurred');
        }
    };
   }
//    console.log(imageUrl)
    return (
        <MainDiv>
            <Toaster
              position="top-right"
              />
            <TitleInputBox>
                <h4>Title</h4>
                <TitleInput type="text" value={title} onChange={handleTitleChange} />
            </TitleInputBox>
            <SubHeadingBox>
                <h4>SubHeading</h4>
                <TitleInput type="text" value={subHeading} onChange={handleSubHeadingChange} />
            </SubHeadingBox>
            <ContentBox>
                <h4>Description</h4>
                <ContentBoxInput title='Content' value={description} onChange={handleDescriptionChange} />
            </ContentBox>
            <ImageUrlBox>
                <h4>Image </h4>
                <UploadImage image={image} setImage={setImage} setImageUrl={setImageUrl} />
            </ImageUrlBox>
            <CategoryBox>
                <h4>Add categories</h4>
                <Category selectedList={categoryList} setSelectedList={setCategoryList}/>
            </CategoryBox>
            {/* <TitleInputBox>
                <h4>Permalink</h4>
                <TitleInput type="text" value={permalink} onChange={(e)=>setPermalink(e.target.value)} />
            </TitleInputBox>
            <TitleInputBox>
                <h4>Structured Data</h4>
                <TitleInput type="text" value={structuredData} onChange={(e)=>setStructuredData(e.target.value)} />
            </TitleInputBox>  */}
            <UploadButtonDiv>
            <UploadButton onClick={handleUploadClick}>Upload {loading && <LoaderIcon/>}</UploadButton>
            </UploadButtonDiv>
            
        </MainDiv>
    );
}

export default BlogUpload;
