import React, { useState } from 'react';
import styled from 'styled-components';

const MainDiv= styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`
const CustomInput = styled.input`
`
const CustomTextArea = styled.textarea`
  width: 300px;
  height: 100%;
  background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.132);
    outline: none;
    padding: 5px;
    border-radius: 8px;
`
interface props{
  setImage: React.Dispatch<React.SetStateAction<File | null>>;
  image:File | null;
  setImageUrl:React.Dispatch<React.SetStateAction<string>>;
}
const  UploadImage = ({image,setImageUrl, setImage}:props) => {
  

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // const formData = new FormData()
      setImage(file)
      setImageUrl(URL.createObjectURL(file))

    }
  };

  return (
    <MainDiv>
      <CustomInput
        type="file"
        data-load="favicon"
        accept="image/*"
        onChange={handleFileChange}
      />
      
     
    </MainDiv>
  );
};

export default UploadImage;