import React from 'react'
import styled from 'styled-components'

const MainDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 25px 0 25px;
    align-items: center;
`
const Heading = styled.p`
  font-size: 24px;
  font-weight: 600;
`
const FeedbackSubscriberTitleBox = () => {
  return (
    <MainDiv>
        <Heading>Feedback Subscribers</Heading>
    </MainDiv>
  )
}

export default FeedbackSubscriberTitleBox