import React, { useEffect, useState } from "react";
import styled from "styled-components";
import copyIcon from '../../assets/pngs/copy-icon.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useAuthState } from "../../store/authSlice/hook";
import { getFeedbackSubscriber } from "../../utils/fetch";
import copyAllIcon from '../../assets/pngs/copy-all.png'

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 25px 40px 25px;
  position: relative;
  
`;
const MainCard = styled.div`
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0dfdf;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  
`;
const HeadingDiv = styled.div`
  height: 40px;
  padding: 0 25px 0 25px;
  border-bottom: 1px solid #e0dfdf;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`;
const Option = styled.p`
  
  &.serial {
    width: 20%;
  }
  &.email {
    width: 40%;
  }
  &.date {
    width: 30%;
  }
`;
const ContentBox = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
`;
const Box = styled.div`
  height: 40px;
  padding: 0 25px 0 25px;
  border-bottom: 1px solid #e0dfdf;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;
const CopyIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  &:active{
    width: 14px;
    height: 14px;
  }
`
const CopyAllIcon = styled.img`
  position: absolute;
  right: 50px;
  top: -60px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:active{
    transform: scale(0.8);
  }
`
const CopyText = styled.h5`
  position: absolute;
  right: 90px;
  top: -52px;
`

const SubscriberDetailCard = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const onPageChange = (page: number) => setCurrentPage(page);
  const {token} = useAuthState()
  const [subscribers, setSubscriber] = useState<any[]>([])

  useEffect(()=>{
    const fetchSubscriber= async ()=>{
      if (token) {
        try {
          const subscriberData =await getFeedbackSubscriber(token)
          setSubscriber(subscriberData.data)
        } catch (error) {
          console.error("Failed to fetch contacts", error);
        }
      }
    };
    fetchSubscriber()

    
  },[token])
  const sortedSubscribers = [...subscribers].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  
  const getAllEmails = () => {
    return sortedSubscribers.map(sub => sub.email).join(", ");
  };
  return (
    <MainDiv>
       <CopyToClipboard text={getAllEmails()}>
        <CopyAllIcon src={copyAllIcon} alt="Copy All" />
      </CopyToClipboard>
      <CopyText>Copy All</CopyText>
      <MainCard>
        <HeadingDiv>
          <Option className="serial">S.No</Option>
          <Option className="email">Email</Option>
          <Option className="date">Date</Option>
          
        </HeadingDiv>
        <ContentBox>
          {sortedSubscribers?.map((subs, index)=>(
            <Box key={index}>
            <Option className="serial" >{index+1}</Option>
            <Option className="email">{subs.email}</Option>
            <Option className="date">{new Date(subs.updatedAt).toLocaleDateString('en-GB')}</Option>
            <CopyToClipboard text={subs.email}>
            <CopyIcon src={copyIcon} alt="" />
            </CopyToClipboard>
          </Box>
          ))}
              
        </ContentBox>
      </MainCard>

    </MainDiv>
  );
};

export default SubscriberDetailCard;
