import React from 'react'
import styled from 'styled-components'

const MainDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 25px 0 25px;
    align-items: center;
    justify-content: space-between;
`
const Heading = styled.p`
  font-size: 24px;
  font-weight: 600;
`
const OptionContainer= styled.div`
  padding: 10px;
  margin-right: 120px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 2px solid #03c9873c;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
`
const Option = styled.div<{ selected:string}>`
  font-family: "Open Sans";
font-size: 12px;
font-style: normal;
font-weight: 600;
padding: 8px 8px 8px 8px;
border-radius: 8px;
cursor: pointer;
&:hover{
    /* background-color: #03c98716; */
    border: 1px solid #858484;
  }

  &.All{
    background-color: ${(props)=>props.selected==="All"?'#03c9872b':''};
    border:${(props)=>props.selected==="All"?' 1px solid black':''};
  }
  &.Deposit{
    background-color: ${(props)=>props.selected==="Deposit"?'#03c9872b':''};
    border:${(props)=>props.selected==="Deposit"?' 1px solid black':''};
  }
 &.Trade{
    background-color: ${(props)=>props.selected==="Trade"?'#03c9872b':''};
    border:${(props)=>props.selected==="Trade"?' 1px solid black':''};
  }
  &.Withdraw{
    background-color: ${(props)=>props.selected==="Withdraw"?'#03c9872b':''};
    border:${(props)=>props.selected==="Withdraw"?' 1px solid black':''};
  }
  &.Overall{
    background-color: ${(props)=>props.selected==="Overall"?'#03c9872b':''};
    border:${(props)=>props.selected==="Overall"?' 1px solid black':''};
  }
  &.HelpUs{
    background-color: ${(props)=>props.selected==="HelpUs"?'#03c9872b':''};
    border:${(props)=>props.selected==="HelpUs"?' 1px solid black':''};
  }
`
interface props{
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  selected:string;
}
const DetailTitleBox = ({selected,setSelected}:props) => {
  return (
    <MainDiv>
        <Heading>Detailed Feedback</Heading>
        <OptionContainer>
          <Option onClick={()=>setSelected('All')} className='All' selected={selected}>All</Option>
          <Option onClick={()=>setSelected('Deposit')} className='Deposit' selected={selected}>Deposit</Option>
          <Option onClick={()=>setSelected('Trade')} className='Trade' selected={selected}>Trade</Option>
          <Option onClick={()=>setSelected('Withdraw')} className='Withdraw' selected={selected}>Withdraw</Option>
          <Option onClick={()=>setSelected('Overall')} className='Overall' selected={selected}>Overall</Option>
          <Option onClick={()=>setSelected('HelpUs')} className='HelpUs' selected={selected}>Help Us</Option>

        </OptionContainer>
    </MainDiv>
  )
}

export default DetailTitleBox